<template>
  <v-container
    id="login"
    class="fill-height justify-center"
    tag="section"
    justify="center"
    align="center"
  >
    <v-row align="center" justify="end" no-gutters>
      <v-col cols="12" sm="6" md="5">
        <login-wizard />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'Login',
    components: {
      LoginWizard: () => import('@/components/login/LoginWizard'),
    }
  }
</script>
